import { APP_INITIALIZER, FactoryProvider } from "@angular/core";

import { AuthService } from "./auth.service";

function initRefreshTokenFactory(authService: AuthService) {
  return () => {
    authService.refreshToken();
  };
}

export const initRefreshTokenProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initRefreshTokenFactory,
  deps: [AuthService],
  multi: true
};
