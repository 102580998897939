import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { ProfileService } from "../profile/profile.service";
import { firstValueFrom, map, skipWhile, take } from "rxjs";
import { AuthService } from "./auth.service";

export const isSuspended: CanActivateFn = async (route) => {
  const router = inject(Router);
  const profileService = inject(ProfileService);
  const authService = inject(AuthService);

  const isLoggedIn = await firstValueFrom(authService.getLoggedInSubject());

  if (!isLoggedIn) {
    return router.parseUrl("/login");
  }

  return firstValueFrom(
    profileService.organization$.pipe(
      skipWhile((organizations) => organizations === undefined),
      take(1),
      map((organizations) => {
        if (organizations?.[0].isSuspended && route.data["skipBlockRedirect"] !== false) {
          return router.parseUrl("/suspended-acccount");
        }

        return true;
      })
    )
  );
};
