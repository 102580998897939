import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { AuthService } from "../../domain/auth/auth.service";
import { Scopes } from "@libs/shares/models/auth.model";

export const adminGuard: CanActivateFn = async () => {
  const router = inject(Router);
  const authService = inject(AuthService);

  const user = await firstValueFrom(authService.getUser());

  if (user && user.scopes.includes(Scopes.SuperAdminRead)) {
    return true;
  }
  return router.parseUrl("/dashboard");
};
