import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { first, firstValueFrom, forkJoin, fromEvent, Observable, timer } from "rxjs";
import { distinctUntilChanged, map, startWith } from "rxjs/operators";
import { IVariant, UnleashClient } from "unleash-proxy-client";
const DEFAULT_TIMEOUT = 500;

@Injectable({
  providedIn: "root"
})
export class BrowserUnleashToggleService extends UnleashClient {
  private readonly readyEvent$: Observable<Event> = fromEvent(this, "ready");

  // update triggers only when something changes
  private readonly updateEvent$: Observable<Event> = fromEvent(this, "update");

  private readonly errorEvent$: Observable<Event> = fromEvent(this, "error");

  constructor() {
    super({
      appName: "devportal",
      environment: environment.unleash.environment,
      url: environment.unleash.url,
      clientKey: environment.unleash.clientKey
    });

    this.start();
  }

  onReadyEvent(): Promise<void> {
    // eslint-disable-next-line no-restricted-syntax
    return new Promise((res) => {
      firstValueFrom(
        forkJoin({
          ready: this.readyEvent$.pipe(first()),
          // we need wait first update because init with bootstrap params emit ready immediately
          firstUpdate: this.updateEvent$.pipe(first())
        })
      ).then(() => res());

      firstValueFrom(this.errorEvent$).then(() => res());

      firstValueFrom(timer(DEFAULT_TIMEOUT)).then(() => res());
    });
  }

  setId(id: string) {
    this.updateContext({ userId: id });
  }

  getFeature(featureKey: string): boolean {
    return this.isEnabled(featureKey);
  }

  getFeature$(featureKey: string): Observable<boolean> {
    return this.updateEvent$.pipe(
      startWith(null),
      map(() => this.getFeature(featureKey)),
      distinctUntilChanged()
    );
  }

  override getVariant(featureKey: string): IVariant {
    return super.getVariant(featureKey);
  }

  getVariant$(featureKey: string): Observable<IVariant> {
    return this.updateEvent$.pipe(
      startWith(null),
      map(() => this.getVariant(featureKey)),
      distinctUntilChanged(
        (prev, cur) =>
          prev.enabled === cur.enabled && prev.name === cur.name && prev.payload?.value === cur.payload?.value
      )
    );
  }
}
