import { HttpClient } from "@angular/common/http";
import { Translation, TranslocoLoader, provideTransloco as provideTranslocoRoot } from "@ngneat/transloco";
import { Injectable, PLATFORM_ID, inject, isDevMode } from "@angular/core";
import { environment } from "../environments/environment";
import { isPlatformBrowser } from "@angular/common";

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
  private isBrowser: boolean = isPlatformBrowser(inject(PLATFORM_ID));
  constructor(private httpClient: HttpClient) {}

  getTranslation(lang: string) {
    const url = this.isBrowser ? `/assets/i18n/${lang}.json` : `${environment.portal.url}/assets/i18n/${lang}.json`;
    return this.httpClient.get<Translation>(url);
  }
}

export function provideTransloco() {
  return provideTranslocoRoot({
    config: {
      availableLangs: ["ar", "el", "ja", "ko", "ru", "zh", "en"],
      defaultLang: "en",
      fallbackLang: "en",
      reRenderOnLangChange: true,
      prodMode: !isDevMode()
    },
    loader: TranslocoHttpLoader
  });
}
