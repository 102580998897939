import { Route } from "@angular/router";
import { canAccess } from "../../domain/auth/auth.guard";

export const profileRoutes: Route[] = [
  {
    path: "profile",
    canActivateChild: [canAccess],
    loadComponent: () => import("./profile.component"),
    children: [
      {
        path: "account",
        loadComponent: () => import("./profile-account.component"),
        title: "Account information"
      },
      {
        path: "company",
        loadComponent: () => import("./profile-company.component"),
        title: "Company information"
      },
      {
        path: "billing",
        loadComponent: () => import("./profile-billing.component"),
        title: "Billing information"
      },
      {
        path: "delete",
        loadComponent: () => import("./profile-delete.component"),
        title: "Delete"
      },
      {
        path: "",
        redirectTo: "account",
        pathMatch: "full"
      }
    ]
  }
];
