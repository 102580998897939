import { Route } from "@angular/router";
import { scopesGuard } from "../../../domain/auth/scope.guard";
import { Scopes } from "@libs/shares/models/auth.model";

export const customerDetailsRoutes: Route[] = [
  {
    path: "customers/:orgId",
    loadComponent: () => import("./customer-detail.component"),
    title: "customer-detail",
    children: [
      {
        path: "general",
        loadComponent: () => import("./customer-detail-general.component")
      },
      {
        path: "subscriptions",
        loadComponent: () => import("./customer-detail-subscriptions.component")
      },
      {
        path: "statistics",
        loadComponent: () => import("./customer-detail-statistics.component")
      },
      {
        path: "users",
        loadComponent: () => import("./customer-detail-users.component")
      },
      {
        path: "billing",
        loadComponent: () => import("./billing-address/organization-billing.component")
      },
      {
        path: "payment",
        loadComponent: () => import("./payment-data/payment-data.component")
      },
      {
        path: "audit-log",
        canActivate: [scopesGuard([Scopes.SuperAdminLogs])],
        loadComponent: () => import("./audit/customer-detail-audit.component")
      },
      {
        path: "",
        redirectTo: "general",
        pathMatch: "full"
      }
    ]
  }
];
