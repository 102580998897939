import { Injectable, inject } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  private authService = inject(AuthService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.isRefreshingAccessToken$.pipe(
      filter((isRefreshing) => !isRefreshing),
      take(1),
      switchMap(() => this.authService.getAccessToken()),
      switchMap((accessToken) => {
        const authReq = req.clone({ setHeaders: { Authorization: `Bearer ${accessToken}` } });
        return next.handle(authReq).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
              this.authService.refreshToken(); // Trigger a refresh
              return this.retryRequest(req, next); // Implement retry logic
            } else {
              return throwError(() => error);
            }
          })
        );
      })
    );
  }

  private retryRequest(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.getAccessToken().pipe(
      switchMap((accessToken) => {
        const authReq = req.clone({ setHeaders: { Authorization: `Bearer ${accessToken}` } });
        return next.handle(authReq);
      })
    );
  }
}
