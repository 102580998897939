import { Route } from "@angular/router";
import { canAccess } from "../../domain/auth/auth.guard";
import { scopesGuard } from "../../domain/auth/scope.guard";
import { Roles } from "@libs/shares/models/auth.model";
import { featureToggleGuard } from "../../domain/unleash/unleash.guard";

export const settingsRoutes: Route[] = [
  {
    path: "settings",
    canActivateChild: [canAccess, scopesGuard(Roles.Admin)],
    loadComponent: () => import("./settings.component"),
    children: [
      {
        path: "users",
        title: "Users",
        canActivate: [featureToggleGuard("multipleUsers")],
        loadComponent: () => import("./users.component")
      },
      {
        path: "saml",
        title: "SAML Configuration",
        canActivate: [featureToggleGuard("saml")],
        loadComponent: () => import("./saml.component")
      },
      {
        path: "",
        redirectTo: "users",
        pathMatch: "full"
      }
    ]
  }
];
