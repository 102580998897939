import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { AuthService } from "./auth.service";
import { Scopes } from "@libs/shares/models/auth.model";

export const scopesGuard = (requiredScopes: Scopes[]) => {
  return async () => {
    const router = inject(Router);
    const authService = inject(AuthService);

    const user = await firstValueFrom(authService.getUser());

    if (user && requiredScopes.every((scope) => user.scopes.includes(scope))) {
      return true;
    }
    return router.parseUrl("/dashboard");
  };
};
