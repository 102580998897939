import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { SharedGuardService } from "./shared-guard.service";

export const kycGuard: CanActivateFn = async () => {
  const router = inject(Router);
  const sharedGuardService = inject(SharedGuardService);

  const user = await sharedGuardService.hasUser();

  if (!user) {
    return router.parseUrl("/login");
  }

  const hasVerification = await sharedGuardService.hasVerificationSinceDate();
  if (!hasVerification) {
    return true;
  }

  if (await sharedGuardService.rejectedKyc()) {
    return router.parseUrl("/suspended-account");
  }

  return router.parseUrl("/profile-setup");
};
