import { APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { TUI_SANITIZER } from "@taiga-ui/core";
import { NgDompurifySanitizer } from "@tinkoff/ng-dompurify";
import { TraceService, createErrorHandler } from "@sentry/angular";
import { Router } from "@angular/router";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideHttpClient } from "@angular/common/http";
import { IUnleashToggleService, UnleashToggleService } from "./domain/unleash/unlease.service";
import { BrowserUnleashToggleService } from "./domain/unleash/unleash.service.browser";
import { AuthService } from "./domain/auth/auth.service";
import { ProfileService } from "./domain/profile/profile.service";
import { encrypt } from "@libs/shares/utils/crypto.utils";
import { SubscriptionService } from "./domain/subscription/subscription.service";
import * as Sentry from "@sentry/angular";
import { CrmService } from "./domain/crm/crm.service";
import { SurveyService } from "./domain/survey/survey.service";

export const appBrowserConfig = {
  providers: [
    provideHttpClient(),
    provideAnimations(),
    {
      provide: UnleashToggleService,
      useClass: BrowserUnleashToggleService
    },
    {
      provide: TUI_SANITIZER,
      useClass: NgDompurifySanitizer
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
        logErrors: true
      })
    },
    {
      provide: TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        //
      },
      deps: [TraceService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          authService: AuthService,
          profileService: ProfileService,
          toggleService: IUnleashToggleService,
          subscriptionService: SubscriptionService,
          crmService: CrmService,
          surveyService: SurveyService
        ) =>
        () => {
          authService
            .getLoggedInSubject()
            .asObservable()
            .subscribe((isLoggedIn) => {
              if (isLoggedIn) {
                profileService.refreshOrganizations().then(() => {
                  const org = profileService.organizations()?.[0];
                  if (org?.id) {
                    const encryptedId = encrypt(org.id);
                    toggleService.setId(encryptedId);
                    Sentry.setUser({ id: encryptedId, username: encryptedId });
                    crmService.getVerificationInfo(org.id, org.entityType);
                    surveyService.getRequiredSurveys(org.id);
                  }
                });
                subscriptionService.getSubscriptions();
              }
            });
          return true;
        },
      deps: [AuthService, ProfileService, UnleashToggleService, SubscriptionService, CrmService, SurveyService],
      multi: true
    }
  ]
};
