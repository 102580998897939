<div class="bg">
  <div class="bg-img-right"></div>
  <div class="bg-img-left"></div>
</div>
<tui-root [tuiMode]="themeModeSwitcher.currentTheme()" class="h-full">
  @if (themeModeSwitcher.currentTheme() === "onDark") {
    <tui-theme-night />
  }
  <!-- content of your app -->
  <router-outlet></router-outlet>
  <!-- If you need, you can add something between Taiga portal layers: -->
  <ng-container ngProjectAs="tuiOverContent">
    <!-- Content over app content -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverDialogs">
    <!-- Content over dialogs -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverAlerts">
    <!-- Content over notifications -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverPortals">
    <!-- Content over dropdowns -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverHints">
    <!-- Content over hints -->
  </ng-container>
</tui-root>
