import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { AuthService } from "../../domain/auth/auth.service";
import { SharedGuardService } from "../../domain/auth/shared-guard.service";

export const profileSetupGuard: CanActivateFn = async () => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const sharedGuardService = inject(SharedGuardService);

  const user = await firstValueFrom(authService.getUser());

  if (!user) {
    return router.parseUrl("/login");
  }

  const hasVerification = await sharedGuardService.hasVerificationSinceDate();
  if (!hasVerification) {
    return router.parseUrl("/verification");
  }
  if (user.consentTypes.length > 0) {
    return router.parseUrl("/consent");
  }

  if (user.setupCompleted) {
    return router.parseUrl("/dashboard");
  }
  return true;
};
