import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { UnleashToggleService } from "./unlease.service";

export const featureToggleGuard = (featureName: string) => {
  return async () => {
    const router = inject(Router);
    const toggleService = inject(UnleashToggleService);

    await toggleService.onReadyEvent();

    if (!featureName) {
      console.error("no feature flag name given for check");
      return router.navigate(["/dashboard"]);
    }

    if (!toggleService.isEnabled(featureName)) {
      return router.navigate(["/dashboard"]);
    }

    return true;
  };
};
