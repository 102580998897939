import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { SubscriptionService } from "../subscription/subscription.service";

export const consentRejectedGiven: CanActivateFn = async () => {
  const router = inject(Router);
  const subscriptionService = inject(SubscriptionService);

  if (subscriptionService.activeSubscription()?.agreement === "rejected") {
    return true;
  }

  return router.parseUrl("/dashboard");
};
