<div class="tablet:rounded-xl bg-day-bg-bg-main dark:bg-night-bg-bg-3ry p-3">
  <div class="flex justify-end">
    <svg
      (click)="dialogRef.close()"
      class="text-day-text-icons-gray-500 dark:text-night-text-icons-gray-500 hover:text-day-text-icons-main hover:dark:text-night-text-icons-main h-4 w-4 cursor-pointer"
    >
      <use href="/assets/icons/close.svg#img" />
    </svg>
  </div>
  <div class="flex flex-col gap-4 p-3">
    <div class="flex gap-4">
      <img class="h-12 w-12" src="/assets/cookie.png" />
      <div class="oneinch-text-medium-13 flex flex-1 flex-col gap-4">
        <span> {{ "cookie.dialog.description" | transloco }}</span>
        <transloco [key]="'cookie.dialog.link'" [params]="legalUrls"></transloco>
      </div>
    </div>
    <div class="tablet:gap-4 flex justify-between gap-3">
      <button
        tuiButton
        data-test-id="acceptCookiePolicy"
        (click)="accept()"
        appearance="brand-primary"
        size="m"
        class="flex-1"
      >
        {{ "cookie.dialog.button.accept" | transloco }}
      </button>
      <button
        tuiButton
        data-test-id="decline"
        (click)="dialogRef.close()"
        appearance="button-info"
        class="!oneinch-text-medium-13 flex-1"
        size="m"
      >
        {{ "cookie.dialog.button.decline" | transloco }}
      </button>
    </div>
  </div>
</div>
