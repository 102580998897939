import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { SharedGuardService } from "../../domain/auth/shared-guard.service";

export const loginGuard: CanActivateFn = async () => {
  const router = inject(Router);
  const sharedGuardService = inject(SharedGuardService);

  const user = await sharedGuardService.hasUser();

  if (!user) {
    return true;
  }

  if (user.consentTypes.length > 0) {
    return router.parseUrl("/consent");
  }

  const hasVerification = await sharedGuardService.hasVerificationSinceDate();
  if (!hasVerification) {
    return router.parseUrl("/verification");
  }

  const setupCompleted = await sharedGuardService.isSetupCompleted();
  if (!setupCompleted) {
    return router.parseUrl("/profile-setup");
  }

  return router.parseUrl("/dashboard");
};
