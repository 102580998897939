// auth.service.ts
import { inject, Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { ProfileService } from "../profile/profile.service";
import { CrmService } from "../crm/crm.service";
import { EntityVerificationStatus, VerificationType } from "@libs/shares/dto/entityVerificationDto";
import { filter, firstValueFrom, map } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { SurveyService } from "../survey/survey.service";

@Injectable({
  providedIn: "root"
})
export class SharedGuardService {
  private authService = inject(AuthService);
  private profileService = inject(ProfileService);
  private crmService = inject(CrmService);
  user = this.authService.getUser();
  surveyService = inject(SurveyService);

  organization = this.profileService.organization$.pipe(
    takeUntilDestroyed(),
    filter((organizations) => Array.isArray(organizations) && organizations.length > 0),
    map((organizations) => organizations![0])
  );

  requiredSurveyList = this.surveyService.requiredSurveys$.pipe(
    takeUntilDestroyed(),
    filter((surveys) => surveys !== undefined)
  );

  kycInfo = this.crmService.verificationInfo$.pipe(
    takeUntilDestroyed(),
    filter((kycInfo) => kycInfo !== undefined)
  );

  async hasUser() {
    return firstValueFrom(this.user);
  }

  async hasVerificationSinceDate() {
    const kycInfo = await firstValueFrom(this.kycInfo);
    return !(
      kycInfo &&
      (kycInfo.type === VerificationType.kycFree || !kycInfo.type) &&
      (!kycInfo.status ||
        kycInfo.status === EntityVerificationStatus.pending ||
        kycInfo.status === EntityVerificationStatus.sent)
    );
  }

  async rejectedKyc() {
    const kycInfo = await firstValueFrom(this.kycInfo);
    if (kycInfo && kycInfo.status === EntityVerificationStatus.rejected) {
      return true;
    }
    return false;
  }

  async isSetupCompleted() {
    return (await firstValueFrom(this.user))?.setupCompleted;
  }
}
