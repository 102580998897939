import { Route } from "@angular/router";
import { canAccess } from "../../domain/auth/auth.guard";
import { adminGuard } from "./admin.guard";
import { customerDetailsRoutes } from "./customer-detail/customer-detail.routes";

export const superAdminRoutes: Route[] = [
  {
    path: "",
    loadComponent: () => import("../layout/base-layout.component"),
    canActivateChild: [canAccess, adminGuard],
    children: [
      {
        path: "admin",
        loadComponent: () => import("../layout/admin-layout.component"),
        title: "admin",
        children: [
          {
            path: "customers",
            loadComponent: () => import("./manage-customers.component")
          },
          ...customerDetailsRoutes,
          {
            path: "",
            redirectTo: "customers",
            pathMatch: "full"
          }
        ]
      }
    ]
  }
];
