import { importProvidersFrom } from "@angular/core";
import {
  TitleStrategy,
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation
} from "@angular/router";
import { EventPluginsModule } from "@tinkoff/ng-event-plugins";
import { defaultTranslocoMarkupTranspilers } from "ngx-transloco-markup";
import { appRoutes } from "./app.routes";
import { provideTransloco } from "./transloco-root.module";
import { TuiDialogModule, TuiRootModule, tuiSvgOptionsProvider } from "@taiga-ui/core";
import { initRefreshTokenProvider } from "./domain/auth/init-refresh-token.provider";
import { AUTH_HTTP_INTERCEPTORS, AuthHttpClient } from "./domain/auth/auth-client.service";
import { AccessTokenInterceptor } from "./domain/auth/auth.interceptor";
import { TemplatePageTitleStrategy } from "./pageTitle.strategy";
import { WALLET_CONNECT_PROJECT_ID } from "./domain/wallet/wallet-connect.service";
import { environment } from "../environments/environment";

export const sharedAppConfig = {
  providers: [
    tuiSvgOptionsProvider({
      path: "https://taiga-ui.dev/assets/taiga-ui/icons"
    }),
    provideTransloco(),
    defaultTranslocoMarkupTranspilers(),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation(), withComponentInputBinding()),
    importProvidersFrom([TuiRootModule, TuiDialogModule, EventPluginsModule]),
    { provide: "googleTagManagerId", useValue: "GTM-55R73P9" },
    { provide: "googleTagManagerResourcePath", useValue: "https://track.1inch.io/mtg" },
    initRefreshTokenProvider,
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
    {
      provide: AUTH_HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true
    },
    { provide: WALLET_CONNECT_PROJECT_ID, useValue: environment.walletConnectProjectId },
    AuthHttpClient
  ]
};
